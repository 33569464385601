import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Space, Typography, message, Spin } from "antd";
import { useForm, useWatch, Controller } from "react-hook-form";
import {
  ReactSelectField,
  RangePickerField,
} from "../../../../../atoms/FormElement";
import { useDispatch, useSelector } from "react-redux";
import { contractService } from "../../../../../../configs/constants";
import axios from "../../../../../../utils/axiosInceptor";
import { Calendar } from "../../../../../../assets/svg";
import dayjs from "dayjs";
import { getNewNotifications, getNotifications } from "../../../../Notification/ducks/action";

var _ = require("lodash");

const defaultVal = {
  ticketStatus: "",
  dateRange: [dayjs(), dayjs().add(30, 'days')],
};

const ExportTicket = (props) => {
  const [submited, setSubmited] = useState(false);
  const [loader, setLoader] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues: defaultVal,
  });

  const dispatch: any = useDispatch();

  useEffect(() => {
    const initData: any = { value: "open", label: "Open" };
    setValue("ticketStatus", initData);
  }, []);

  const onBack = () => {
    reset();
    props.onCancel();
  };

  const onFinish = async (values) => {

    if (
      values.dateRange[1] === undefined ||
      values.dateRange[0] === undefined
    ) {
      setError("dateRange", {
        type: "custom",
        message: "Please add start and end date",
      });
    } else {
      setLoader(true);

      try {
        const res = await axios.get(
          contractService +
          `/maintenance/export/tickets?ticketStatus=${values.ticketStatus.value
          }&startDate=${values.dateRange[0].format(
            "YYYY-MM-DD"
          )}&endDate=${values.dateRange[1].format("YYYY-MM-DD")}`
        );
        
        if (res.data.result === 'No Data found') {
          message.error(
            "No Data found"
          );
        } else {
          message.success(
            "We will notify to you once your export file is ready to download"
          );
          setTimeout(async () => {

              const res1 = await dispatch(getNotifications(1));
              const res2 = await dispatch(getNewNotifications(1));

              // const link = document.createElement('a');
              // link.href = res1?.data[0]?.identifier || res2?.data[0]?.identifier;
              // link.click();
              // message.info("Please check notifications");
            }, 3000);
          }
        
        setLoader(false);
          //BUG FIX: ref 8201
      } catch (e) {
        setLoader(false);
        message.error("Error");
      }
      props.onCancel();
    }
  };

  const optionList = [
    {
      value: "open",
      label: "Open",
    },
    {
      value: "closed",
      label: "Closed",
    },
    {
      value: "all",
      label: "All",
    },
  ];
  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
              {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}></Row>
          <Row gutter={24}>
            <Col span={12}>
              <RangePickerField
                isRequired={true}
                control={control}
                label="Date Range"
                setValue={setValue}
                fieldname="dateRange"
                validate={errors.dateRange && "error"}
                // initValue={[dayjs(), dayjs().add(30, 'days')]}
                // initValue={[dayjs('2024-02-10'), dayjs('2024-02-15')]}
                iProps={{
                  suffixIcon: <Calendar />,
                  placeholder: ["Start Date", "End Date"],
                  onCalendarChange: (date: any) => {
                    setValue("dateRange", date);
                  },
                  onChange: (date: any) => {
                    setValue("dateRange", date);
                  },
                }}
              />
            </Col>

            <Col span={12}>
              <ReactSelectField
                isRequired={true}
                fieldname="ticketStatus"
                label="Ticket Status"
                control={control}
                iProps={{ placeholder: "Ticket Status" }}
                rules={{
                  required: "Please select Property",
                }}
                selectOption={
                  optionList?.length > 0 &&
                  optionList?.map((item) => ({
                    value: item?.value,
                    label: item?.label,
                  }))
                }
                validate={errors.ticketStatus && "error"}
                validMessage={
                  errors.ticketStatus && errors?.ticketStatus?.message
                }
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                className="mb-0"
                validateStatus={errors.dateRange && "error"}
                help={errors?.dateRange && errors?.dateRange?.message}
              ></Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button className="ag-gray-button-outline" onClick={onBack}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                disabled={loader}
                className="property_card_btn"
                htmlType="submit"
              >
                Export
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
    </Form>
  );
};

export default ExportTicket;
