import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Image,
  Typography,
} from "antd";

import { useNavigate, useLocation } from "react-router-dom";

import moment from "moment";
import * as status_types from "./constants";
import Logo from "../../../assets/img/ag.png";
import placeholder from '../../../assets/img/BUILDING_placeholder.svg';
import { getPresignedImage } from "../../../utils/media";

const { Text, Title } = Typography;

const PropertyCard = (props) => {
  const role = JSON.parse(window.localStorage.getItem("login-response"))?.data
    ?.role_name;
  const history = useNavigate();
  const location: any = useLocation();
  const [exportData, setExportData] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const { property} = props;

  const {
    // name
    buildingName,
    // property_info,
    address,
    // asset_id,
    erpLaName,
    _id,
    // asset_status,
    status,
    // createdAt,
    updatedAt,
    image,
    unitDataCount,
    occupied,
    vacant,	
    reserve,
    propertyId	
  } = property;

  const csvLinkEl = useRef();
 




  useEffect(() => {
    const data = [];
    if (csvData.length > 0) {
      csvData.map((value, key) => {
        data.push({
          case_id: value.case_id,
          type:
            value.CaseCategoryClassification[0]?.CaseCategory?.category_type,
          subtype:
            value.CaseCategorySecondaryClassification[0]?.CaseSubCategory
              ?.category_sub_type,
          tenant_name:
            value.CaseRoleTenant?.length > 0
              ? value.CaseRoleTenant[0]?.Party?.name
              : "",
          technician:
            value.CaseRoleTechnician?.length > 0
              ? value.CaseRoleTechnician[0]?.Party?.name
              : "",
          appartment: value.Asset.name,
          recuring: value.ChildCase[0]?.parent_id,
          description: value.description,
          date:
            (value.ticket_date ? value.ticket_date : "") +
            " " +
            (value.ticket_start_time
              ? moment(value.ticket_start_time, "hh:mm:ss").format("LT") +
              " to "
              : "") +
            (value.ticket_end_time
              ? moment(value.ticket_end_time, "hh:mm:ss").format("LT")
              : ""),
        });
      });
      setExportData(data);
      setTimeout(() => {
        //@ts-ignore
        csvLinkEl?.current?.link.click();
      }, 300);
    }
  }, [csvData]);

  const [propertyImage, setPropertyImage] = useState("");



  useEffect(() => {
    caller();
  }, [image]);

  const presinedImageUrl = async (media) => {
    if (media) {
      const url = await getPresignedImage(media);
      return url;
    }
  };

  const caller = async () => {
    let img;
    if (image?.length) {
      img = await presinedImageUrl(image[0]);
    }
    setPropertyImage(img);
  };



  return (
    <Col title={'Click to View Detail'}>
    <Card  bordered={false} className="propcard" onClick={() => history(`/property-detail/${propertyId}`)} style={{cursor:'pointer'}}>
      <Row gutter={[8, 8]}>
        <Col   xs={24} xl={8} lg={24} className="imageCard">
          <Image
            preview={false}
            title={buildingName}
            style={{background:'#e9ecf5'}}
            height={120}
            placeholder={true}
            alt="Al Ghurair Residence"
            src={placeholder}
            fallback={Logo}
          />
        </Col>
        
        <Col xs={24} xl={16} className="list_view_content">

          <Card
          //  cover={<img alt="example" src={placeholder} />}
            style={{paddingBottom:'0px'}}
            bordered={false}
            title={buildingName}
            className="ag-nospace-head ag-nospace-body"
          >
            <Text className="lineHeight28 ag-default  mb-0" title={address?.addressLine2 +" "+ address?.addressLine1 + " " + address?.emirates }>
              <span className="prop_heading">City:</span> {address?.emirates}
            </Text>
        
            <Text className="lineHeight28 ag-default  mb-0">
              <span className="prop_heading">Property Admin:</span> {erpLaName && erpLaName}
            </Text>

            <Text className="lineHeight28 ag-default  mb-0">
              <span className="prop_heading">No. of Units:</span> {unitDataCount > 0 ? unitDataCount : 0 }
            </Text>

            
            <Text className="lineHeight28 ag-default  mb-0">
              <span className="prop_heading">Units Vacant:</span> {vacant > 0 ? vacant : 0}
            </Text>

            
            <Text className="lineHeight28 ag-default  mb-0">
              <span className="prop_heading">Units Occupied:</span> {occupied > 0 ? occupied : 0}
            </Text>

            {/* <Text className="lineHeight28 ag-default  mb-0">
              <span className="prop_heading">Units Reserve:</span> {reserve}
            </Text> */}

            
         
          </Card>
          
        
        </Col>
      </Row>

    </Card>
    </Col>

  );

};

export default PropertyCard;
