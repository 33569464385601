import React from "react";

// others
import { changeHistoryColumn, mappedStatusHistory } from "../utils";
import Grid from "../../../../atoms/Grid";
import { useSelector } from "react-redux";

interface IProps {
  parentState: (obj: object) => void;
  onModalClose:any
}

const index = (props: IProps) => {
  // redux states
  const { bookingDetails } = useSelector((state: any) => state.newBooking);

  return (
    <div className="bf-main view-booking edit-payment-schedule change-history">
      <Grid
        columns={changeHistoryColumn(props?.onModalClose,bookingDetails)}
        data={mappedStatusHistory(bookingDetails)}
      />
    </div>
  );
};

export default index;
