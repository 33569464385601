import React, { useEffect } from "react";
import { Row, Col, Pagination, Card } from "antd";
import PropertyCard from "../../atoms/PropertyCard";
import ReviewCard from "../../atoms/ReviewCard";
import EmptyPlaceholder from "../../atoms/EmptyPlaceholder";

const PropertyList = (props) => {
  const { onExport, propertyData } = props;

  const onProperty = (param) => {
    // console.log("Property", param);
  };

  useEffect(() => {
    const viewby = document.querySelector('.viewby') as HTMLInputElement;
    var isCardsview = document.getElementsByClassName('prop-card-grid');
    if (isCardsview.length > 0) {
      viewby.style.display = "block";
    }
  },[])

  return (
   <>
   <Row gutter={[6, 12]} style={{marginTop:"6px"}}>
      {props.pagination && (
        <Col span={24}>
          <Pagination
            className="ag-property-text-pagination"
            total={props.count}
            showTotal={(total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} Properties`
            }
            defaultPageSize={props.perPage}
            showSizeChanger={false}
            current={props.currentPage}
            onChange={(e) => props.onpageChange(e)}
          />
        </Col>
      )}
      </Row>
    <Row gutter={[6, 6]} style={{marginTop:"6px"}}>
      {/* {props.pagination && (
        <Col span={24}>
          <Pagination
            className="ag-property-text-pagination"
            total={props.count}
            showTotal={(total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} Properties`
            }
            defaultPageSize={props.perPage}
            showSizeChanger={false}
            current={props.currentPage}
            onChange={(e) => props.onpageChange(e)}
          />
        </Col>
      )} */}
      <div className="prop-card-grid">
        {
          props.data && props.data?.length>0 ?
          props.data.map((item, index) => (

            <Col xs={24} lg={8} xl={8} key={item?._id}>
              <PropertyCard
                property={item}
                exportAction={onExport}
                propertyData={propertyData}
                propertyAction={onProperty}
                onStatusChanged={props.onStatusChanged}
              />
            </Col>
          )) :
          <Col xs={24} xl={24}>
            <Card bordered={false}>
              <EmptyPlaceholder label="Properties" />
            </Card>
          </Col>
          }
    </div>

      {
        props.type === "r" &&
        props.data &&
        props.data.map((item, index) => (
          <Col span={12} key={index}>
            <ReviewCard data={item} />
          </Col>
        ))
      }
    </Row >
   </>
  );
};

export default PropertyList;
