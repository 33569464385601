import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import {
  propertyService,
  unitService,
} from "../../../../configs/constants";

export const getUnits = (bySearch, limit, page, search, searchBy,filter = {},paymentPlan=false,loadingStatus=null) => {

  let tempfilter = JSON.parse(JSON.stringify(filter)) 
  // console.log(paymentPlan,'paymentPlan')
  loadingStatus && loadingStatus(true)
  if(filter && filter['usagesType'] === 'all') {
    delete tempfilter.usagesType
  } 

  if(filter && filter['unitStatus'] === 'all' ) {
    delete tempfilter.unitStatus
  } 
  
  return async (dispatch) => {
    let params = new URLSearchParams({
        ...tempfilter,
      });
    let tenants = "";
    let url = "";
 
    try{

      if (bySearch) {
        tenants = `${unitService}/unit/unitList`; ///leasing/getAll`;
        url = `${tenants}?&perPage=${limit}&page=${page}`;
      } else {
        tenants = `${unitService}/unit/unitList`; ///leasing/getAll`;
        url = `${tenants}?perPage=${limit}&page=${page}&${searchBy}=${`${search}&paymentPlan=${paymentPlan}&`+params.toString()} `;
      }
  
  
      const {
        data: { result },
      } = await axios.get(url);
      dispatch({
        type: action_types.GET_UNITS,
        data: result,
      });
      loadingStatus && loadingStatus(false)
    }catch(error){
      loadingStatus && loadingStatus(false)
      console.log(error)
    }
   
  };
};

export const setFilters = data => {
  
  return async (dispatch) => {
    dispatch({
      type: action_types.SET_UNIT_FILTERS,
      data: data,
    });
  }
}

export const clearFilters = () => {
  return async (dispatch) => {
    dispatch({
      type: action_types.SET_UNIT_FILTERS,
      data: {},
    });
  }
}

export const getUnitDetails = async (id) => {

  const url = `${propertyService}/leasing/getById?unitId=${id}`;
  
  const {
    data: { result },
  } = await axios.get(url);

  return result
};