import React, { useEffect } from "react";
import { Col, Typography } from "antd";
import { InputField, ReactSelectField } from "../../../../../atoms/FormElement";
import { useDispatch, useSelector } from "react-redux";
import { getGeoData } from "../../ducks/actions/addInfoAction";
import { subLocationList, locationsList } from "../../../ducks/constants";
import { WrappedMap } from "../../../../../../utils/WrappedMap";
import { EQARI_GOOGLE_KEY } from "../../../../../../configs/constants";
import { getConfigData } from "../../../ducks/actions";

var _ = require("lodash");
const { Text } = Typography;

export default (props) => {
  const { control, errors, setValue, noEdit } = props;

  const dispatch: any = useDispatch();

  const configData = useSelector((state: any) => state.dashboard.configData);

  const countries = configData?.nationalityList?.filter(
    (c) => c === "United Arab Emirates"
  );






  return (
    <>
    

      <Col span={6}>
        <InputField
          isRequired={!noEdit}
          label="Address Line 1"
          fieldname="addressLine1"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Enter address Line 1",
            readOnly: noEdit,
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          rules={{ required: "Please enter address Line 1" }}
          validate={errors.addressLine1 && "error"}
          validMessage={errors.addressLine1 && errors.addressLine1.message}
        />
      </Col>
      <Col span={6}>
        <InputField
          isRequired={!noEdit}
          label="Address Line 2"
          fieldname="addressLine2"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Enter address Line 2",
            readOnly: noEdit,
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          rules={{ required: "Please enter address Line 2" }}
          validate={errors.addressLine2 && "error"}
          validMessage={errors.addressLine2 && errors.addressLine2.message}
        />
      </Col>
      <Col span={6}>
        <ReactSelectField
          isRequired={!noEdit}
          label="Emirate"
          fieldname="emirates"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Select one",
            isDisabled: noEdit,
            className: `${noEdit ? "disablerselect" : ""}`,
          }}
          selectOption={_.map(configData?.emiratesList, (item) => ({
            value: item,
            label: item,
          }))}
          rules={{ required: "Please select emirate" }}
          validate={errors.emirates && "error"}
          validMessage={errors.emirates && errors.emirates.message}
        />
      </Col>

      <Col span={6}>
        <ReactSelectField
          isRequired={!noEdit}
          label="Country"
          fieldname="country"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Select one",
            isDisabled: noEdit,
            className: `${noEdit ? "disablerselect" : ""}`,
          }}
          selectOption={_.map(countries, (item) => ({
            value: item,
            label: item,
          }))} 
          rules={{ required: "Please enter country" }}
          validate={errors.country && "error"}
          validMessage={errors.country && errors.country.message}
        />
      </Col>

      <Col span={6}>
        <ReactSelectField
          isRequired={!noEdit}
          label="Community"
          fieldname="community"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Select one",
            isDisabled: noEdit,
            className: `${noEdit ? "disablerselect" : ""}`,
          }}
          selectOption={_.map(
            configData?.locationlist?.sublocation,
            (item, key) => ({
              value: key,
              label: item,
            })
          )}
          rules={{ required: "Please select" }}
          validate={errors.community && "error"}
          validMessage={errors.community && errors.community.message}
        />
      </Col>

      <Col span={6}>
        <InputField
          isRequired={!noEdit}
          label="Latitude"
          fieldname="latitude"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "25.0554",
            readOnly: noEdit,
            type: "number",
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          validate={errors.latitude && "error"}
          validMessage={errors.latitude && errors.latitude.message}
        />
      </Col>
      <Col span={6}>
        <InputField
          isRequired={!noEdit}
          label="Longitude"
          fieldname="longitude"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "55.1522",
            readOnly: noEdit,
            type: "number",
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          validate={errors.longitude && "error"}
          validMessage={errors.longitude && errors.longitude.message}
        />
      </Col>

      {/* adding these fields after story 9873 in sprint 41 and 42 */}
       

      <Col span={6}>
        <InputField
          isRequired={false}
          label="Makani No"
          fieldname="makaniNumber"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Makani No",
            readOnly: noEdit,
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          // rules={{ required: "Please enter address Line 1" }}
          // validate={errors.addressLine1 && "error"}
          // validMessage={errors.addressLine1 && errors.addressLine1.message}
        />
      </Col>



      <Col span={6}>
        <InputField
          isRequired={false}
          label="Alternate Property Name"
          fieldname="alternatePropertyName"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Alternate Property Name",
            readOnly: noEdit,
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          // rules={{ required: "Please enter address Line 1" }}
          // validate={errors.addressLine1 && "error"}
          // validMessage={errors.addressLine1 && errors.addressLine1.message}
        />
      </Col>

      <Col span={6}>
        <InputField
          isRequired={false}
          label="Land No"
          fieldname="landNnumber"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Land No",
            readOnly: noEdit,
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          // rules={{ required: "Please enter address Line 1" }}
          // validate={errors.addressLine1 && "error"}
          // validMessage={errors.addressLine1 && errors.addressLine1.message}
        />
      </Col>

      <Col span={6}>
        <InputField
          isRequired={false}
          label="DM. No."
          fieldname="DMNumber"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "DM. No.",
            readOnly: noEdit,
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          // rules={{ required: "Please enter address Line 1" }}
          // validate={errors.addressLine1 && "error"}
          // validMessage={errors.addressLine1 && errors.addressLine1.message}
        />
      </Col>
    </>
  );
};
