/* eslint-disable react/prop-types */
import * as React from "react";
import {
  useLocation,
  Routes,
  Route,
  Navigate,
  useNavigate,

  useSearchParams
} from "react-router-dom";
import { useSelector } from "react-redux";
import { deleteCookie } from "../utils/helper";
import Login from "../app/pages/Login";
import ShowNotice from "../app/pages/ShowNotice";
import SignUp from "../app/modules/SignUp";
import ForgotPassword from "../app/modules/Login/ForgotPassword";
import ChangePassword from "../app/modules/Login/ChangePassword";
import Success from "../app/modules/Login/Success";
import PropertyList from "../app/modules/Properties/PropertyList";
import CreateProperty from "../app/modules/Properties/AddProperty/CreateProperty";
import OfferByTenant from "../app/modules/Properties/OfferByTenant";
import Leads from "../app/modules/LeadManagement/Leads/Leads";
import LeadsDetail from "../app/modules/LeadManagement/LeadsDetail/leadsDetail";
import PropertyDetail from "../app/modules/Properties/PropertyDetail";
import Reviews from "../app/modules/Reviews";
import PropertyReview from "../app/modules/Reviews/PropertyReview";
import Tenants from "../app/modules/Tenants";
import Units from "../app/modules/Units";
import Notices from "../app/modules/Notices";
import AddTenant from "../app/modules/Tenants/AddTenant";
import Maintenance from "../app/modules/Maintenance";
import VisitScheduling from "../app/modules/Properties/VisitScheduling";
import PropertiesOnMap from "../app/modules/Properties/PropertiesOnMap";
import OpportuntiyList from "../app/modules/LeadManagement/OpportunityList";
import OpportunityDetail from "../app/modules/LeadManagement/OpportunityDetail";
import TenantDetail from "../app/modules/TenantDetail";
import ContractBookingForm from "../app/modules/ContractFlow/ContractBookingForm";
import LeaseForm from "../app/modules/LeasingForm";
import TenantDetails from "../app/modules/Tenants/TenantDetails";
import UnitDetail from "../app/modules/Properties/PropertyDetail/UnitDetail";
import EjariList from "../app/modules/Ejari";
import BookingList from "../app/modules/BookingListing";
import BookingformList from "../app/modules/BookingformList";
import ContractsList from "../app/modules/Contracts";
import Notification from "../app/modules/Notification";
import SignUpNew from "../app/modules/SignUpNew";
import OTP from "../app/modules/SignUpNew/OTP";
import CreatePassword from "../app/modules/SignUpNew/CreatePassword";
import PostDatedCheques from "../app/modules/Contracts/PostDatedCheques";
import ContractDetail from "../app/modules/Contracts/ContractDetail";
import AllProspects from "../app/modules/Tenants/AllProspects";
import ContractTenantDetails from "../app/modules/ContractTenantDetails";
import NotificationCalendar from "../app/modules/Notification/NotificationCalendar";
import ChequesApproval from "../app/modules/ChequesApproval";
import Managers from "../app/modules/Managers";
import ServiceProvider from "../app/modules/ServiceProvider";
import Tickets from "../app/modules/Maintenance/Tickets/Tickets";
import Technicians from "../app/modules/Maintenance/Technicians/Technicians";
import Roster from "../app/modules/Maintenance/Roster/Roster";
import Zones from "../app/modules/Maintenance/Zones/Zones";
import Configurations from "../app/modules/Configurations";
import Shifts from "../app/modules/Configurations/Shifts/Shifts";
import Categories from "../app/modules/Configurations/Categories/Categories";
import PublicHolidays from "../app/modules/Configurations/PublicHolidays/PublicHolidays";
import BookingListing from "../app/modules/BookingListing";
import BookingRenewalListing from "../app/modules/BookingRenewalListing";
import Dashboard from "../app/modules/Dashboard";

import RenewalForm from "../app/modules/LeasingFormNew/RenewalForm";
import BookingForm from "../app/modules/LeasingFormNew/BookingForm";
import NotFound from "../app/modules/NotFound";
import ContactUs from "../app/modules/Contact Us";
import Unauthorized from "../app/modules/Unauthorize";
import NewBookingForm from "../app/modules/BookingForm/BookingForm";
import ViewBookingForm from "../app/modules/BookingForm/ViewBookingForm";
import { useDispatch } from "react-redux";
import { getNewNotifications, getNotifications } from "../app/modules/Notification/ducks/action";

function isAuthenticated() {
  const login = JSON.parse(localStorage.getItem("login-response"));
  try {
    const { token } = login;
    if (!token) {
      return false;
    }
  } catch (err) {
    return false;
  }
  return true;
}

function clearAll() {
  deleteCookie("landlordUser");
  localStorage.clear();
  sessionStorage.clear();
}

const Pages = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch: any = useDispatch();

  const noticeId = searchParams.get("noticeId")

  React.useEffect(() => {
    if (!noticeId) {
      if (!isAuthenticated()) navigate("/login", { state: location });
    }
  }, [noticeId]);


  const runNotifications = async (mins: number) => {
    setInterval(async () => {
      const res1 = await dispatch(getNotifications(1));
      const res2 = await dispatch(getNewNotifications(1))
    }, mins * 60000)
  }

  React.useEffect(() => {
    isAuthenticated() && runNotifications(2);
  }, []);

 
 const userScreenAccess:any = useSelector((state :any)=>state?.userScreens?.userScreenAccess);
  
const withScreenAccess = (Component, allowedScreen) => {

    if (userScreenAccess?.length != 0 &&  !userScreenAccess.includes(allowedScreen)) {
      return <Navigate to="/unauthorized" />;
    }
    return <Component {...props} />;
};


  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/notice" element={<ShowNotice />} />
      <Route path="/register" element={<SignUp />} />
      <Route path="/signup" element={<SignUpNew />} />
      <Route path="/otp" element={<OTP />} />
      <Route path="/create-password" element={<CreatePassword />} />
      <Route path="/success" element={<Success />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/change-password" element={<ChangePassword />} /> 
      <Route path="/unauthorized" element={<Unauthorized />} /> 
      {isAuthenticated() ? (
        <>
        
          <Route
            path="/offer-by-tenant/:id"
            element={<OfferByTenant />}
          />
          <Route
            path="/booking-form/:id"
            element={<BookingForm />}
          />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/properties" element={withScreenAccess(PropertyList,'properties')} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/tenants/:id" element={<Tenants />} />
          <Route path="/managers/:id" element={<Managers />} />
          <Route path="/add-tenant" element={<AddTenant />} />
          <Route path="/property-review" element={<PropertyReview />} />
          <Route path="/create-a-property" element={<CreateProperty />} />
          <Route path="/leads" element={<Leads />} />
          <Route path="/lead-detail/:id" element={<LeadsDetail />} />
          <Route path="/property-detail/:id" element={<PropertyDetail />} />
          <Route
            path="/property-detail/:id/unit/:id"
            element={<UnitDetail />}
          />
          <Route path="/maintenance/:id" element={<Maintenance />} />
          <Route path="/visit-scheduling/:id" element={<VisitScheduling />} />
          <Route path="/visit-scheduling" element={<VisitScheduling />} />
          <Route path="/properties-map" element={<PropertiesOnMap />} />
          <Route path="/opportunities" element={<OpportuntiyList />} />
          <Route
            path="/opportunity-detail/:id/:assetId/:partyId"
            element={<OpportunityDetail />}
          />
          <Route
            path="/detail-tenant/:id/:partyId"
            element={<ContractTenantDetails />}
          />
          <Route path="/tenants"  element={withScreenAccess(Tenants,'tenants')} />
          <Route path="/users"    element={withScreenAccess(Managers,'users')} />

          <Route path="/cheques/:id" element={<ChequesApproval />} />
          <Route path="/tenants-detail/:id" element={<TenantDetails />} />
          <Route path="/bookingforms" element={withScreenAccess(BookingformList,'leases')} />
          <Route path="/bookings/form" element={<BookingForm />} />
          <Route path="/newbookingform" element={<NewBookingForm />} />
          <Route path="/newbookingform/:leaseId" element={<NewBookingForm />} />
          <Route
            path="/viewbookingform/:leaseId"
            element={<ViewBookingForm />}
          />
          <Route path="/bookings/form/:LEASEID" element={<BookingForm />} />
          <Route path="/renewals/form/:LEASEID" element={<RenewalForm />} />

          <Route
            path="/leads/:leadId/booking-form/:assetId/:partyId"
            element={<ContractBookingForm />}
          />
          <Route path="/ejari" element={<EjariList />} />
          <Route path="/bookings" element={withScreenAccess(BookingListing,'bookings')}/>
          <Route path="/booking-Renewals" element={withScreenAccess(BookingRenewalListing,'renewalsInProgress')}/>
          <Route path="/contracts" element={withScreenAccess(ContractsList,'upcomingRenewals')} />
          <Route path="/contract-detail/:id" element={<ContractDetail />} />
          <Route path="/payments" element={withScreenAccess(PostDatedCheques,'payments')} />
          <Route path="/prospects" element={<AllProspects />} />
          <Route path="/prospect-detail/:id" element={<TenantDetails />} />
          <Route path="/notifications" element={<Notification />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route
            path="/notifications/calendar"
            element={<NotificationCalendar />}
          />

          <Route path="/maintenance" element={<Maintenance />} />

          {/* Maintenance Sub Types */}
          <Route path="/service-providers" element={withScreenAccess(ServiceProvider,'serviceProviders')} />
          <Route path="/tickets" element={withScreenAccess(Tickets,'tickets')}  />
          <Route path="/technicians" element={<Technicians />} />
          <Route path="/roster" element={<Roster />} />
          <Route path="/zones" element={<Zones />} />

          <Route path="/configurations" element={<Configurations />} />
          <Route path="/open-hours" element={<Shifts />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/notices"  element={withScreenAccess(Notices,'notices')} />
          <Route path="/public-holidays" element={<PublicHolidays />} />

          <Route path="/units" element={<Units />} />
          <Route path="*" element={<NotFound/>}></Route>
        </>
      ) : (
        <>
          {clearAll()}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </>
      )}
    </Routes>
    //  </BrowserRouter>
  );
};

export default Pages;