export const SET_FORM_STATE = "SET_FORM_STATE";
export const CREATE_BOOKING = "CREATE_BOOKING";
export const CREATE_RENEWAL = "CREATE_RENEWAL";
export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const DELETE_BOOKING = "DELETE_BOOKING";
export const GET_CONFIG_DATA = "GET_CONFIG_DATA";
export const SET_BOOKING_FORM = "SET_BOOKING_FORM";
export const GET_PAYMENT_PLANS = "GET_PAYMENT_PLANS";
export const CLEAR_BOOKING_FORM = "CLEAR_BOOKING_FORM";
export const GET_BOOKING_DETAIL = "GET_BOOKING_DETAIL";
export const GET_LANDLORD_USERS = "GET_LANDLORD_USERS";
export const GET_BOOKING_UNIT_DETAILS = "GET_BOOKING_UNIT_DETAILS";
export const GET_TENANT_DETAILS_BY_EMAIL = "GET_TENANT_DETAILS_BY_EMAIL";
export const GET_TEMPLATES_DATA = "GET_TEMPLATES_DATA";
export const GET_PARKING_BAYS = "GET_PARKING_BAYS";

// update renewal if renewal status is renewal_sent or renewal decline in sprint 42 story 9986

export const REFRESH_RENWAL = 'REFRESH_RENWAL';

